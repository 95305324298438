define('webapp/services/search-service', ['exports', 'webapp/utils/tree-utils'], function (exports, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function parseWildcard(wildcard) {
    wildcard = wildcard || '';
    var segments = wildcard.split('/');
    var searchTerm = segments[segments.length - 1];
    var categorySlugs = segments.slice(0, -1);
    return { searchTerm: searchTerm, categorySlugs: categorySlugs };
  }

  function findCategoryChildren(cats, type) {
    var cat = (0, _treeUtils.findInTree)(cats, function (x) {
      return x.type === type;
    });
    return cat && cat.children || [];
  }

  exports.default = Ember.Service.extend(Ember.Evented, {
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    viewService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    router: Ember.inject.service(),
    languageService: Ember.inject.service(),
    trackingService: Ember.inject.service(),

    searchResult: null,
    perPage: 20,
    currentSearchTerm: '', //updated only after search is triggered
    searchTerm: '',
    location: null,
    geoDistance: null,
    sort: null,
    itemTypes: null,
    enableMaxRange: false,
    selectedCategories: [],
    selectedTags: [],
    selectedEvent: null,
    aggs: {},
    globalCategoryCounts: {},
    globalTagCounts: {},
    displayOptions: {},

    exactSearch: false, //controlls fuzziness

    pagingIdx: 0,
    totalHits: 0,

    onRouteChange: function onRouteChange() {
      this.set('suggestions', null);
    },


    selectedCategorySlugs: Ember.computed('selectedCategories.@each.slug', function () {
      var selectedCategories = this.get('selectedCategories');
      return selectedCategories.map(function (cat) {
        return cat.slug;
      });
    }),

    isSearchRoute: Ember.computed('viewService.currentModel', function () {
      var currentModel = this.get('viewService.currentModel');
      return currentModel && currentModel.type === 'search';
    }),

    resetSearch: function resetSearch() {
      this.setProperties({
        searchTerm: '',
        selectedCategories: [],
        selectedTags: [],
        selectedEvent: null,
        itemTypes: null,
        sort: null,
        geoDistance: null,
        enableMaxRange: null
      });
    },
    setSearchTerm: function setSearchTerm(term) {
      this.set('searchTerm', term);
    },
    newSearch: function newSearch() {
      this.setProperties({
        pagingIdx: 0
      });
      return this.updateSearch();
    },
    setPagingIdx: function setPagingIdx(pagingIdx) {
      this.set('pagingIdx', pagingIdx);
      this.updateSearch();
    },
    getLocation: function getLocation() {
      return this.get('location');
    },
    fetchGlobalAggs: function fetchGlobalAggs() {
      var _this = this;

      if (!this.globalAggsPromise) {
        var myStorage = this.get('myStorage');
        this.globalAggsPromise = myStorage.search.ajaxPost({
          query: {
            site: this.get('cachedData.cachedSite._id')
          },
          data: { size: 0, itemTypes: this.get('itemTypes'), aggregations: true }
        }).then(function (res) {
          _this.setProperties({
            globalCategoryCounts: res.aggs && res.aggs.categoryIds,
            globalTagCounts: res.aggs && res.aggs.tags
          });
        });
      }

      return this.globalAggsPromise;
    },
    getCategoryFilter: function getCategoryFilter() {
      var categoryFilterOperation = this.get('categoryFilterOperation');
      var selectedCategories = this.get('selectedCategories').map(function (x) {
        return x._id;
      });
      var rootCategories = this.get('rootCategories');
      var filterRootCategories = this.get('filterRootCategories');

      var filterType = categoryFilterOperation === 'or' ? 'or' : 'and';

      var catFilters = [];
      if (filterRootCategories && rootCategories && rootCategories.length) {
        catFilters.push({ type: filterType, items: rootCategories });
      }

      catFilters.push({
        type: filterType,
        items: selectedCategories
      });

      return catFilters;
    },
    getTagFilter: function getTagFilter() {
      return this.get('selectedTags');
    },
    updateSearch: function updateSearch(_locationSearchTerm) {
      var _this2 = this;

      var locationSearchTerm = this.get('tempSearchTerm') || _locationSearchTerm;
      var searchTerm = this.get('searchTerm');
      this.set('currentSearchTerm', searchTerm);

      var size = this.get('perPage');
      var from = size * this.get('pagingIdx');

      var showMap = this.get('displayOptions.showMap');

      var searchRequest = {
        itemTypes: this.get('itemTypes'),
        searchTerm: searchTerm,
        size: size,
        from: from,
        // size: showMap ? 10000 : size,
        // from: showMap ? 0 : from,
        exactSearch: this.get('exactSearch'),
        categoryFilter: this.getCategoryFilter(),
        tagFilter: this.getTagFilter(),
        location: this.getLocation(),
        sort: this.get('sort'),
        geoDistance: this.get('geoDistance'),
        enableMaxRange: this.get('enableMaxRange'),
        highlightFields: ['title'],
        aggregations: true,
        showMarkers: showMap,
        showRelations: this.get('showRelations')
      };

      if (searchRequest.sort === 'dreampoints') {
        searchRequest.sortOrder = 'desc';
      }

      return this.get('myStorage').search.ajaxPost({
        data: searchRequest,
        query: {
          lang: this.get('languageService.selectedLanguage'),
          site: this.get('cachedData.cachedSite._id')
        }
      }).then(function (res) {
        if (res.hits) {
          if (locationSearchTerm && locationSearchTerm.match(/^\d+$/)) {
            // if tempsearchTerm is a number, assume its a zip code and show those items first
            var zipHits = [];
            var otherHits = [];
            res.hits.forEach(function (hit) {
              if (Ember.get(hit, 'address.zip') === locationSearchTerm) {
                zipHits.push(hit);
              } else {
                otherHits.push(hit);
              }
            });

            res.hits = [].concat(zipHits, otherHits);
          }
          res.hits.forEach(function (hit, idx) {
            hit.idx = idx + from + 1;
          });
        }

        // if (showMap) {
        //   res.markers = res.hits;
        //   res.hits = res.hits.slice(from, from + size);
        // }

        _this2.setProperties({
          totalHits: res.totalHits,
          searchResult: res,
          aggs: res.aggs,
          suggestions: null
        });
        _this2.trigger('searchReceived');
        return res;
      });
    },
    searchAsYouType: function searchAsYouType(searchTerm) {
      var _this3 = this;

      if (searchTerm.length) {
        var highlightFields = ['title', 'translations.title'];

        var searchRequest = {
          itemTypes: this.get('itemTypes'),
          searchTerm: searchTerm,
          size: 5,
          exactSearch: this.get('exactSearch'),
          highlightFields: highlightFields,
          aggregations: false,
          markers: false
        };

        var myStorage = this.get('myStorage');
        return myStorage.search.ajaxPost({ param: 'suggest', data: searchRequest }).then(function (suggestions) {
          _this3.set('suggestions', suggestions);
        });
      } else {
        this.set('suggestions', null);
      }
    },
    transitionSearch: function transitionSearch() {
      var router = this.get('router');
      var searchTerm = this.get('searchTerm');
      var trackingService = this.get('trackingService');
      var selectedCategories = this.get('selectedCategories');
      var wildcardParam = selectedCategories.map(function (x) {
        return x.slug;
      }).join('/');
      if (wildcardParam) {
        wildcardParam += '/';
      }
      if (searchTerm) {
        wildcardParam += searchTerm;
      }

      trackingService.gaSearch(searchTerm);

      var activePage = this.get('cmsService.activePage');

      if (activePage && activePage.slug === '') {
        // do not update url, if search page is homepage
        // routing in api wouldn't work that way, because we do not know which page we are on
        this.updateSearch();
      } else {
        router.transitionTo('page', this.getSearchPageRoute(wildcardParam));
      }
    },


    // for widget-searchbar to prefill region search
    transitionSearchRegion: function transitionSearchRegion() {
      var _this4 = this;

      this.set('location', null);
      this.set('sort', null);
      var router = this.get('router');
      var trackingService = this.get('trackingService');
      var searchTerm = this.get('searchTerm');
      var selectedCategories = this.get('selectedCategories');

      var suggestions = this.get('suggestions');

      var wildcardParam = selectedCategories.map(function (x) {
        return x.slug;
      }).join('/');
      if (wildcardParam) {
        wildcardParam += '/';
      }

      if (suggestions && suggestions.length) {
        if (searchTerm) {
          wildcardParam += searchTerm;
        }
      } else {
        this.set('tempSearchTerm', searchTerm);
        Ember.run.later(function () {
          _this4.set('tempSearchTerm', '');
        }, 5000);
      }

      trackingService.gaSearch(searchTerm);

      router.transitionTo('page', this.getSearchPageRoute(wildcardParam));
    },
    getSearchPageRoute: function getSearchPageRoute(addSeg) {
      var languageService = this.get('languageService');
      var page = this.get('page');
      var pageRoute = void 0;
      if (page && this.get('isSearchRoute')) {
        pageRoute = languageService.translateSlug(page);
      } else {
        pageRoute = this.get('cmsService.dynamicPageRoutes.search');
      }
      if (pageRoute && addSeg) {
        pageRoute += '/';
      }
      if (addSeg) {
        pageRoute += addSeg;
      }
      return pageRoute;
    },


    showPaging: Ember.computed('totalHits', 'perPage', function () {
      return this.get('totalHits') > this.get('perPage');
    }),

    setupSearchCategories: function setupSearchCategories(rawCategories, selectedCategorySlugs) {
      var catMap = this._catMap;
      if (!catMap) {
        catMap = {};
        (0, _treeUtils.walkTree)(rawCategories, function (cat) {
          catMap[cat.slug] = cat;
        });
        this._catMap = catMap;
      }

      var fullSelectedCategories = selectedCategorySlugs.map(function (slug) {
        return catMap[slug];
      }).filter(function (x) {
        return !!x;
      });

      var rootCategories = this.get('rootCategories');
      var categoryFilters = [];

      if (rootCategories && rootCategories.length) {
        // if rootCategories selected, build seperate category filter for each category
        rootCategories.forEach(function (catId) {
          var foundCat = (0, _treeUtils.findInTree)(rawCategories, function (x) {
            return x._id === catId;
          });
          if (foundCat && foundCat.children && foundCat.children.length) {
            categoryFilters.push({
              title: foundCat.title,
              categories: foundCat.children
            });
          }
        });
      } else {
        // merge all categories into one filter
        var categories = [];
        var itemTypes = this.get('itemTypes');
        itemTypes.forEach(function (type) {
          return categories = categories.concat(findCategoryChildren(rawCategories, type));
        });
        categoryFilters.push({ categories: categories });
      }

      if (fullSelectedCategories.length && this.get('hideParentCategories')) {
        // only show parent categories of selected categories in category filters
        categoryFilters = categoryFilters.map(function (catFilter) {
          var origCategories = catFilter.categories;
          var filteredCategories = [];

          var foundCats = [];
          fullSelectedCategories.forEach(function (cat) {
            var foundCat = (0, _treeUtils.findInTree)(origCategories, function (x) {
              return x._id === cat._id;
            });
            if (foundCat) {
              foundCats.push(foundCat);
            }
          });

          if (foundCats.length) {
            foundCats.forEach(function (cat) {
              // const isParentOf = foundCats.some(_cat => _cat.parent === cat._id);
              if (cat.children &&
              // check if category is parent of some other cat in foundcats
              !(0, _treeUtils.findInTree)(cat.children, function (_cat) {
                return foundCats.some(function (fCat) {
                  return fCat._id === _cat._id;
                });
              })) {
                filteredCategories = filteredCategories.concat(cat.children);
              }
            });
          } else {
            filteredCategories = origCategories;
          }

          return _extends({}, catFilter, { categories: filteredCategories });
        });
      }

      this.set('categoryFilters', categoryFilters);
      this.set('selectedCategories', fullSelectedCategories);
    },
    setupOptions: function setupOptions(options) {
      this.set('categoryFilterOperation', options.categoryFilterOperation);
      this.set('itemTypes', options.types || []);
      this.set('categoryMaxLevel', parseInt(options.categoryMaxLevel) || 100);
      this.set('perPage', parseInt(options.perPage) || 20);
      this.set('rootCategories', options.rootCategories || 0);
      this.set('filterRootCategories', options.filterRootCategories);
      this.set('hideParentCategories', options.hideParentCategories);
      this.set('setCustomCenter', options.setCustomCenter);
      this.set('customCenter', options.customCenter);
      this.set('customZoom', options.customZoom);
      this.set('mapboxSearchCountries', options.mapboxSearchCountries);
      this.set('showRelations', options.showRelations);

      this.set('displayOptions', Ember.getProperties(options, ['showSelectedCategories', 'showSort', 'showSearchbar', 'showLocationSearch', // user location by city lookup
      'showLocationSearchZip', // user location by zip lookup
      'showBrowserLocationButton', // user location by HTML5
      'showSearchFilter', 'showCategoryFilter', 'showCategoryCount', 'showEmptyCats', 'showTagFilter', 'showTagCount', 'showMap', 'showMapCluster', 'showResults', 'perPage', 'itemsViewClass', 'itemsView', 'template', 'types', 'noDetailLink']));
    },
    setupPage: function setupPage(pageRoute, page) {
      var _this5 = this;

      var prevPage = this.get('page');
      if (prevPage && prevPage._id !== page._id) {
        this.resetSearch();
      }

      this.set('page', page);
      var searchSegment = page.restSlug;

      if (page.options) {
        this.setupOptions(page.options);
      }

      this.set('viewService.showSearchbar', true);

      var _parseWildcard = parseWildcard(searchSegment),
          searchTerm = _parseWildcard.searchTerm,
          categorySlugs = _parseWildcard.categorySlugs;

      this.setSearchTerm(searchTerm);
      return this.get('cachedData').getCategories().then(function (res) {
        var rawCategories = res.slice();
        _this5.set('rawCategories', rawCategories);
        _this5.setupSearchCategories(rawCategories, categorySlugs);
      }).then(function () {
        return _this5.fetchGlobalAggs();
      }).then(function () {
        return _this5.newSearch().then(function () {
          _this5.set('isSubSearch', searchTerm || categorySlugs.length);
        });
      });
    }
  });
});