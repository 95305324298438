define('webapp/components/search/search-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var typeToStorage = {
    entry: 'entry',
    event: 'event',
    poi: 'poi',
    product: 'product',
    job: 'job',
    recipe: 'recipe',
    article: 'article',
    campaign: 'campaign'
  };

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    media: Ember.inject.service(),
    searchService: Ember.inject.service(),
    viewService: Ember.inject.service(),

    tagsOpen: false,
    geoSearchModalShown: false,
    selectedDetail: null,

    searchPaneOpen: Ember.computed(function () {
      return !this.get('media.isMobile');
    }),

    didInsertElement: function didInsertElement() {
      this.get('searchService').on('markerClick', this, this.onMarkerClick);
      if (this.get('searchService.displayOptions.showMap')) {
        document.body.classList.add('map');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.get('searchService').off('markerClick', this, this.onMarkerClick);
      document.body.classList.remove('map');
    },
    onMarkerClick: function onMarkerClick(item) {
      this.selectDetail(item);
      if (this.get('media.isMobile')) {
        this.set('searchPaneOpen', true);
      }
    },
    selectDetail: function selectDetail(item) {
      var _this = this;

      var myStorage = this.get('myStorage');
      var itemType = item.itemType;
      var slug = item.slug;

      var storage = typeToStorage[itemType];
      if (!storage) {
        throw new Error('no storage for itemType ' + itemType);
      }

      var param = slug;
      if (itemType === 'entry') {
        // TODO fix this in api
        param = 'entryslug/' + slug;
      }

      return myStorage[storage].ajaxGet({ param: param }).then(function (detailItem) {
        _this.set('selectedDetail', _extends({}, detailItem, {
          itemType: itemType
        }));
        if (detailItem.geoPoint) {
          _this.get('searchService').trigger('showPopup', detailItem, {
            onlyMarker: _this.get('media.isMobile')
          });
        }
      });
    },


    actions: {
      // almost the same as in widget-search
      searchKeyup: function searchKeyup(e) {
        var searchService = this.get('searchService');
        var searchTerm = e.target.value;
        searchService.setSearchTerm(searchTerm);
        if (e.key === 'Enter') {
          searchService.transitionSearch();
        } else {
          Ember.run.debounce(searchService, searchService.searchAsYouType, searchTerm, 200);
        }
      },
      clearSearch: function clearSearch() {
        var searchService = this.get('searchService');
        searchService.setSearchTerm('');
        searchService.transitionSearch();
      },
      setPage: function setPage(pagingIdx) {
        this.get('searchService').setPagingIdx(pagingIdx);
      },
      setSort: function setSort(value) {
        var searchService = this.get('searchService');
        searchService.set('sort', value);
        searchService.updateSearch();
      },
      toggleItemType: function toggleItemType(itemType) {
        var searchService = this.get('searchService');

        var oldType = searchService.get('itemType');

        if (oldType === itemType) {
          searchService.set('itemType', null);
        } else {
          searchService.set('itemType', itemType);
        }

        searchService.updateSearch();
      },
      toggleMaxRange: function toggleMaxRange() {
        var searchService = this.get('searchService');
        searchService.toggleProperty('enableMaxRange');
        searchService.updateSearch();
      },
      toggleTags: function toggleTags() {
        this.toggleProperty('tagsOpen');
      },
      toggleSearchPane: function toggleSearchPane() {
        this.toggleProperty('searchPaneOpen');
      },
      showGeoSearchModal: function showGeoSearchModal() {
        this.set('geoSearchModalShown', true);
      },
      hideGeoSearchModal: function hideGeoSearchModal() {
        this.set('geoSearchModalShown', false);
      },
      categoriesChanged: function categoriesChanged(cats) {
        var searchService = this.get('searchService');
        searchService.set('selectedCategories', cats);
        searchService.transitionSearch();
      },
      setSelectedTags: function setSelectedTags(tags) {
        var searchService = this.get('searchService');

        searchService.set('selectedTags', tags);
        searchService.updateSearch();
      },
      clearSearchTerm: function clearSearchTerm() {
        var searchService = this.get('searchService');
        searchService.set('searchTerm', '');
        searchService.transitionSearch();
      },
      selectDetail: function selectDetail(item) {
        this.selectDetail(item);
      },
      clearDetail: function clearDetail() {
        this.get('searchService').trigger('clearPopups');
        this.set('selectedDetail', null);
      }
    }
  });
});